.simple-numbered-list {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: review-counter;
  li {
    position: relative;
    margin-bottom: 8px;
    padding-left: 32px;
    counter-increment: review-counter;
    font-size: 1.6rem;
    &::before {
      display: block;
      position: absolute;
      z-index: 10;
      left: 0;
      top: 3px;
      content: counter(review-counter);
      width: 17px;
      height: 17px;
      border-radius: 50%;
      line-height: 17px;
      text-align: center;
      color: #fff;
      font-size: 1.3rem;
      font-weight: bold;
      background: $color-primary-dark;
    }
  }
}
