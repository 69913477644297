.DateRangePicker,
.SingleDatePicker {
  .DateRangePickerInput__withBorder {
    display: flex;
    height: 40px;
    align-items: center;
    border-radius: 20px;
    background-color: #f2f3f4;
    border: 1px solid #67707e;
    padding: 0 5px;
  }

  .DateRangePickerInput__withBorder:hover {
    border: 1px solid #67707e;
  }

  .DateInput_input {
    width: 150px;
    background-color: transparent;
    padding: 0 10px;
    border: none !important;
  }

  .DateInput {
    background-color: transparent;
  }

  .DateRangePicker_picker,
  .SingleDatePicker_picker {
    background: transparent;
  }

  .DayPickerNavigation_button__default {
    border: 1px solid transparent;
  }

  .DayPicker_transitionContainer {
    border-radius: 20px;
  }

  .DayPicker__withBorder {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.15);
    border-radius: 20px;

    .CalendarMonth {
      background: transparent;
    }

    .CalendarMonthGrid {
      background: transparent;
    }
  }

  .DayPickerKeyboardShortcuts_show {
    display: none;
  }

  .DateInput_fang.DateInput_fang_1 {
    bottom: 48px !important;
  }

  .CalendarDay__default {
    border: 10px solid #fff;
    background: #f2f3f4;
  }

  .CalendarDay__default:hover {
    border: 10px solid #fff;
    background: #f2f3f4;
  }

  .CalendarDay__selected_span {
    background: #0f7895;
    border: 10px solid #fff;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #0f7895;
    border: 10px solid #fff;
  }

  .CalendarDay__highlighted_calendar,
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #0f7895;
    border: 10px solid #fff;
    color: #fff;
  }

  @media screen and (max-width: 374px) {
    .DateInput {
      width: 110px;
    }
    .DateInput_input {
      width: 110px;
      font-size: 1.6rem;
    }
  }
}

.RangeDatePicker_down .DateRangePicker {
  .DateInput_fang.DateInput_fang_1 {
    top: 48px !important;
    bottom: auto !important;
  }
}
