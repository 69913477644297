.main-heading-section {
  position: relative;
  text-align: center;
  &:after {
    display: block;
    content: '';
    width: 100px;
    height: 4px;
    margin-top: 20px;
    background-color: $color-pink-secondary;
    border-radius: 20px;
  }
}

.main-heading-section-tight {
  position: relative;
  text-align: center;
  &:after {
    display: block;
    content: '';
    width: 100px;
    height: 4px;
    background-color: $color-pink-secondary;
    border-radius: 20px;
  }
}

.is-with-indicator {
  position: relative;
  &:after {
    display: block;
    content: '';
    width: 100px;
    height: 4px;
    margin-top: 5px;
    background-color: $color-pink-secondary;
    border-radius: 20px;
  }
}
.is-with-indicator__left-bl {
  position: relative;
  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    display: block;
    position: absolute;
    background: #1a2c4e;
  }
}
@media (max-width: 550px) {
  .text-padding__24 {
    padding-left: 24px;
  }
}
