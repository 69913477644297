.simple-list-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    margin-bottom: $spacing;
    font-size: 1.6rem;
    .icon-container {
      width: 35px;
      flex-shrink: 0;
      color: $color-hint;
      svg {
        font-size: 2rem;
      }
      &.success {
        svg {
          color: $color-success-main;
        }
      }
    }
    .content-container {
      p {
        margin-top: 0;
        margin-bottom: $spacing;
      }
    }
  }
}
