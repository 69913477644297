.chips-list {
  margin: 0 -4px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    margin: 4px;
  }
}
