.title-case {
  text-transform: capitalize;
}
.text-link {
  cursor: pointer;
  color: #0F7895;
  font-weight: bold;
  font-size: 1.6rem;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.is-deleted {
  opacity: 0.2;
}
