html {
  font-size: 62.5%;
  background-color: $color-bg-main;
}

body {
  margin: 0;
  padding: 0;
  background-color: $color-bg-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: $color-link;
  &:hover {
    text-decoration: none;
  }
}

input::placeholder,
textarea::placeholder {
  color: inherit;
  opacity: 0.5;
  font-weight: normal !important;
}

input[disabled]::placeholder,
textarea[disabled]::placeholder {
  opacity: 0.2;
}
